"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        name: 'api_login_contents',
        path: '/api/login-contents',
        redirect: '',
        meta: { requiresAuth: false }
    }
];
