"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Cart {
    constructor(id, createdUser, createdDatetime, updatedDatetime, products) {
        this.id = id;
        this.createdUser = createdUser;
        this.createdDatetime = createdDatetime;
        this.updatedDatetime = updatedDatetime;
        this.products = products;
    }
    getSize() {
        let size = 0;
        this.products.map((cartProduct) => {
            size += cartProduct.quantity;
        });
        return size;
    }
    getSizeByProduct(productId) {
        let cartProduct = this.products.find((cartProduct) => {
            return productId === cartProduct.id;
        });
        return cartProduct === undefined ? 0 : cartProduct.quantity;
    }
    getNetTotal() {
        let netTotal = 0;
        this.products.map((cartProduct) => {
            netTotal += cartProduct.quantity * cartProduct.priceNet;
        });
        return netTotal;
    }
    getCustomNetTotal() {
        let customNetTotal = 0;
        this.products.map((cartProduct) => {
            customNetTotal += cartProduct.quantity * cartProduct.priceCustomNet;
        });
        return customNetTotal;
    }
    getGrossTotal() {
        let grossTotal = 0;
        let priceBasicNet = 0;
        this.products.map((cartProduct) => {
            priceBasicNet = cartProduct.priceNet;
            if (cartProduct.priceEco) {
                priceBasicNet += cartProduct.priceEco;
            }
            grossTotal += (cartProduct.quantity * priceBasicNet) * ((100 + cartProduct.priceVatPercent) / 100);
        });
        return grossTotal;
    }
    getCustomGrossTotal() {
        let customGrossTotal = 0;
        this.products.map((cartProduct) => {
            if (cartProduct.priceCustomNet !== 0) {
                customGrossTotal +=
                    (cartProduct.quantity * cartProduct.priceCustomNet) * ((100 + cartProduct.priceVatPercent) / 100);
            }
        });
        return customGrossTotal;
    }
    getEcoTotal() {
        let ecoTotal = 0;
        this.products.map((cartProduct) => {
            ecoTotal += cartProduct.priceEco;
        });
        return ecoTotal;
    }
    getEprTotals() {
        let eprNetTotal = 0;
        let eprGrossTotal = 0;
        this.products.map((cartProduct) => {
            eprNetTotal += cartProduct.priceEpr * cartProduct.quantity;
            eprGrossTotal += cartProduct.priceEpr * cartProduct.quantity * ((100 + cartProduct.priceVatPercent) / 100);
        });
        return {
            "eprNetTotal": eprNetTotal,
            "eprGrossTotal": eprGrossTotal
        };
    }
    getCurrencyCode() {
        let [firstElement] = this.products.values();
        return firstElement === null || firstElement === void 0 ? void 0 : firstElement.currencyCode;
    }
}
exports.default = Cart;
