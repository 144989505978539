"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-7157dd5a"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = {
    key: 0,
    class: "grid justify-center mb-4"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = ["src", "alt"];
const vue_3 = require("vue");
const shop_1 = require("../common/utils/shop");
const bannerStore_1 = require("../stores/banner/bannerStore");
const vue_router_1 = require("vue-router");
const toastStore_1 = require("../stores/toast/toastStore");
const BannerFileTypeEnum_1 = require("../store/bannerFiles/BannerFileTypeEnum");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Banner',
    props: {
        type: null
    },
    setup(__props) {
        const props = __props;
        const router = (0, vue_router_1.useRouter)();
        const bannerStore = (0, bannerStore_1.useBannerStore)();
        const toastStore = (0, toastStore_1.useToastStore)();
        const targetUrl = (0, vue_3.ref)('');
        const imgUrl = (0, vue_3.ref)('');
        const bannerType = (0, vue_3.ref)('');
        const currentBreakpoint = (0, vue_3.ref)('');
        const bannerFiles = (0, vue_3.ref)([]);
        const currentBannerIndex = (0, vue_3.ref)(0);
        const altText = (0, vue_3.ref)('');
        const bannerInterval = (0, vue_3.ref)(undefined);
        const rotationInterval = 15000;
        (0, vue_3.onMounted)(() => {
            currentBreakpoint.value = (0, shop_1.getCurrentBreakpoint)();
            bannerType.value = calculateBannerType(props.type);
            window.addEventListener('resize', updateBreakpoint);
        });
        const bannerImgMaxSize = (0, vue_3.computed)(() => {
            if (bannerType.value === BannerFileTypeEnum_1.BannerFileTypeEnum.UNIVERSAL ||
                bannerType.value === BannerFileTypeEnum_1.BannerFileTypeEnum.SEARCH_PAGE_MOBILE ||
                bannerType.value === BannerFileTypeEnum_1.BannerFileTypeEnum.MAIN_PAGE_MOBILE) {
                return 'mobile-banner-size';
            }
            if (bannerType.value === BannerFileTypeEnum_1.BannerFileTypeEnum.SEARCH_PAGE ||
                bannerType.value === BannerFileTypeEnum_1.BannerFileTypeEnum.MAIN_PAGE) {
                return 'desktop-banner-size';
            }
            return '';
        });
        const bannerTransitionName = (0, vue_3.computed)(() => {
            return bannerType.value === BannerFileTypeEnum_1.BannerFileTypeEnum.UNIVERSAL ? 'slide-right' : 'slide';
        });
        const getBannerFiles = async () => {
            try {
                const params = { type: bannerType.value };
                if (bannerType.value === BannerFileTypeEnum_1.BannerFileTypeEnum.SEARCH_PAGE || bannerType.value === BannerFileTypeEnum_1.BannerFileTypeEnum.SEARCH_PAGE_MOBILE) {
                    params.searchPageType = router.currentRoute.value.name;
                }
                bannerFiles.value = await bannerStore.getActiveBannerFiles(router, params);
            }
            catch (error) {
                toastStore.addErrorToast(error.toString());
            }
        };
        const calculateBannerType = (type) => {
            if (type === BannerFileTypeEnum_1.BannerFileTypeEnum.MAIN_PAGE || type === BannerFileTypeEnum_1.BannerFileTypeEnum.MAIN_PAGE_MOBILE) {
                return isMobile() ? BannerFileTypeEnum_1.BannerFileTypeEnum.MAIN_PAGE_MOBILE : BannerFileTypeEnum_1.BannerFileTypeEnum.MAIN_PAGE;
            }
            if (type === BannerFileTypeEnum_1.BannerFileTypeEnum.SEARCH_PAGE || type === BannerFileTypeEnum_1.BannerFileTypeEnum.SEARCH_PAGE_MOBILE) {
                return isMobile() ? BannerFileTypeEnum_1.BannerFileTypeEnum.SEARCH_PAGE_MOBILE : BannerFileTypeEnum_1.BannerFileTypeEnum.SEARCH_PAGE;
            }
            if (type === BannerFileTypeEnum_1.BannerFileTypeEnum.UNIVERSAL) {
                return BannerFileTypeEnum_1.BannerFileTypeEnum.UNIVERSAL;
            }
            return BannerFileTypeEnum_1.BannerFileTypeEnum.MAIN_PAGE;
        };
        const isMobile = () => {
            return currentBreakpoint.value === "sm" || currentBreakpoint.value === "md";
        };
        const updateBreakpoint = async () => {
            currentBreakpoint.value = (0, shop_1.getCurrentBreakpoint)();
            bannerType.value = calculateBannerType(bannerType.value);
        };
        const setBannerFiles = async () => {
            resetAndClearInterval();
            currentBannerIndex.value = 0;
            if (bannerFiles.value.length > 0) {
                setBannerFile(0);
                if (bannerFiles.value.length > 1) {
                    currentBannerIndex.value += 1;
                    startBannerRotation();
                }
            }
        };
        const startBannerRotation = () => {
            resetAndClearInterval();
            if (bannerFiles.value.length > 1) {
                bannerInterval.value = setInterval(() => {
                    setBannerFile(currentBannerIndex.value);
                    currentBannerIndex.value = (currentBannerIndex.value + 1) % bannerFiles.value.length;
                }, rotationInterval);
            }
        };
        const setBannerFile = async (index) => {
            imgUrl.value = bannerFiles.value[index].fileUrl;
            targetUrl.value = bannerFiles.value[index].targetUrl || '';
            altText.value = `banner-${index + 1}`;
        };
        const resetAndClearInterval = () => {
            clearInterval(bannerInterval.value);
            bannerInterval.value = undefined;
        };
        (0, vue_3.watch)(bannerType, async () => {
            await getBannerFiles();
            setBannerFiles();
        });
        (0, vue_3.onUnmounted)(() => {
            bannerFiles.value = [];
            window.removeEventListener('resize', updateBreakpoint);
            resetAndClearInterval();
        });
        return (_ctx, _cache) => {
            return (bannerFiles.value.length && imgUrl.value)
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                    (0, vue_2.createVNode)(vue_2.Transition, {
                        name: (0, vue_2.unref)(bannerTransitionName),
                        mode: "in-out"
                    }, {
                        default: (0, vue_2.withCtx)(() => [
                            ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                                key: imgUrl.value,
                                class: "col-start-1 row-start-1"
                            }, [
                                (targetUrl.value)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("a", {
                                        key: 0,
                                        href: targetUrl.value,
                                        target: "_blank"
                                    }, [
                                        (0, vue_2.createElementVNode)("img", {
                                            src: imgUrl.value,
                                            alt: altText.value,
                                            class: (0, vue_2.normalizeClass)([(0, vue_2.unref)(bannerImgMaxSize), "w-full h-auto"])
                                        }, null, 10, _hoisted_3)
                                    ], 8, _hoisted_2))
                                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("img", {
                                        key: 1,
                                        src: imgUrl.value,
                                        alt: altText.value,
                                        class: (0, vue_2.normalizeClass)([(0, vue_2.unref)(bannerImgMaxSize), "w-full h-auto"])
                                    }, null, 10, _hoisted_4))
                            ]))
                        ]),
                        _: 1
                    }, 8, ["name"])
                ]))
                : (0, vue_2.createCommentVNode)("", true);
        };
    }
});
