"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopConfig = void 0;
class ShopConfig {
    static getAppEnv() {
        return this.config.env.APP_ENV;
    }
    static hasAppEnv() {
        return this.config.hasOwnProperty('env') && this.config['env'].hasOwnProperty('APP_ENV');
    }
    static getLocale() {
        return this.config.env.LOCALE;
    }
    static hasLocale() {
        return this.config.hasOwnProperty('env') && this.config['env'].hasOwnProperty('LOCALE');
    }
    static getShopLocation2IsoCode() {
        if (!this.hasLocale()) {
            return 'HU';
        }
        let locale = this.getLocale();
        return locale.slice(-2);
    }
    static getShopLocation() {
        return this.config.env.SHOP_LOCATION;
    }
    static hasShopLocation() {
        return this.config.hasOwnProperty('env') && this.config['env'].hasOwnProperty('SHOP_LOCATION');
    }
    static getTranslations() {
        return this.config.translations;
    }
    static hasTranslations() {
        return this.config.hasOwnProperty('translations');
    }
    static getAlcarSearchHost() {
        if ('' !== this.config.env.ALCAR_SEARCH_HOST) {
            return this.config.env.ALCAR_SEARCH_HOST;
        }
        return 'https://kiskershop.localhost';
    }
    static getAxaptaPopupHost() {
        if ('' !== this.config.env.AXAPTA_POPUP_HOST) {
            return this.config.env.AXAPTA_POPUP_HOST;
        }
        return 'https://kiskershop.localhost';
    }
    static getLocationApiConnection() {
        return {
            url: this.config.env.LOCATION_API_URL,
            token: this.config.env.LOCATION_API_TOKEN,
        };
    }
    static getCashPaymentModeDisabledLimits() {
        return this.config.cashPaymentModeDisabledLimits;
    }
    static getCompanyData() {
        return this.config.companyData;
    }
}
/* @ts-ignore */
ShopConfig.config = window.shopConfig;
exports.ShopConfig = ShopConfig;
