"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rimProductListFields = exports.valveProductListFields = void 0;
const i18n_1 = require("../../common/i18n");
const shop_1 = require("../../common/utils/shop");
exports.valveProductListFields = [
    {
        key: "productName",
        value: (item) => {
            return `
<div class="product-name cursor-pointer inline-flex">
        <div class="pr-3 flex justify-center items-center textbox textbox-right"
        data-text="${(0, i18n_1.trans)("product_info", "Product info", "rim_search")}">
        <span class="icon marso-icon-info-circle text-2xl text-blue"></span>
    </div>
    <div>
        <div class="font-bold">${item.productName}</div>
    </div>
</div>`;
        },
        label: (0, i18n_1.trans)("product_name_valve", "Product name", "rim_search"),
        visible: true,
    },
    {
        key: "id",
        value: (item) => {
            return `<div class="font-bold">${item.manufacturerSku}</div>`;
        },
        label: (0, i18n_1.trans)("id_valve", "Id", "rim_search"),
        visible: true,
    },
    {
        key: "priceList",
        visible: true,
        value: (item, customerCurrencyCode) => {
            let ret = "";
            let priceEcoNet = item.prices.priceEcoNet;
            if (typeof item.prices.priceListNet !== "number") {
                ret = `${ret}<div>${(0, shop_1.formatPrice)(parseFloat(item.prices.priceListNet) + priceEcoNet, customerCurrencyCode)}</div>`;
            }
            else {
                ret = `${ret}<div>${(0, shop_1.formatPrice)(item.prices.priceListNet + priceEcoNet, customerCurrencyCode)}</div>`;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent &&
                typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (priceVatPercent) {
                ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(item.prices.priceListNet, customerCurrencyCode, { vatPercent: priceVatPercent })}</div>`;
            }
            return ret;
        },
        label: `<div>${(0, i18n_1.trans)("list_price_valve", "List price", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("net_list_price_valve", "net", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("gross_list_price_valve", "gross", "rim_search")}</div>`,
    },
    {
        key: "priceDiscount",
        visible: true,
        label: `<div>${(0, i18n_1.trans)("wholesale_price_valve", "Wholesale price", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("net_wholesale_price_valve", "net", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("gross_wholesale_price_valve", "gross", "rim_search")}</div>`,
        value: (item, customerCurrencyCode) => {
            let price = item.prices.priceDiscountNet;
            let priceEcoNet = item.prices.priceEcoNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let ret = "<div>";
            if (item.prices.needDiscountDisplay) {
                ret = '<div class="text-red-dark">';
            }
            ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price + priceEcoNet, customerCurrencyCode)}</div>`;
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent &&
                typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (priceVatPercent) {
                ret = `${ret}<div>${(0, shop_1.formatPrice)(price + priceEcoNet, customerCurrencyCode, { vatPercent: priceVatPercent })}</div>`;
            }
            let discountPercent = (100 - price / (item.prices.priceListNet / 100)) / 100;
            if (discountPercent > 0) {
                ret = `${ret}<div class="hide-foreign-customer"><i class="marso-icon-promotion_gift"></i> ${(0, i18n_1.n)(discountPercent, "percent")}</div>`;
            }
            ret = `${ret}</div>`;
            return ret;
        },
    },
    {
        key: "priceRetail",
        visible: false,
        label: `<div>${(0, i18n_1.trans)("retail_price_valve", "Retail price", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("net_retail_price_valve", "net", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("gross_retail_price_valve", "gross", "rim_search")}</div>`,
        value: (item, customerCurrencyCode) => {
            let ret = "";
            let priceEcoNet = item.prices.priceEcoNet;
            if (typeof item.prices.priceRetailNet !== "number") {
                ret = `${ret}<div>${(0, shop_1.formatPrice)(parseFloat(item.prices.priceRetailNet) + priceEcoNet, customerCurrencyCode)}</div>`;
            }
            else {
                ret = `${ret}<div>${(0, shop_1.formatPrice)(item.prices.priceRetailNet + priceEcoNet, customerCurrencyCode)}</div>`;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent &&
                typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (priceVatPercent) {
                ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(item.prices.priceRetailNet + priceEcoNet, customerCurrencyCode, { vatPercent: priceVatPercent })}</div>`;
            }
            return ret;
        },
    },
    {
        key: "stock",
        visible: true,
        label: (0, i18n_1.trans)("stock_valve", "Stock", "rim_search"),
        value: (item) => {
            let cell = [];
            let stocks = item.stocks;
            let detailsCell = [];
            if ("undefined" !== typeof stocks.stockDetails) {
                stocks.stockDetails.forEach((item) => {
                    detailsCell.push(`
                        <div class="grid grid-flow-col">
                            <span class="whitespace-nowrap pr-2" title="${item.name}">${item.name}</span>
                            <span class="font-bold text-right">${item.stock}</span>
                        </div>
                    `);
                });
            }
            if ("undefined" !== typeof stocks.stockDetailsStatus) {
                if (detailsCell.length) {
                    cell.push(`<div class="${stocks.stockDetailsStatus} stock-details-box absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10">${detailsCell.join("")}</div>`);
                }
                else {
                    cell.push(`<div class="${stocks.stockDetailsStatus} stock-details-box absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10 text-center text-xl"><span class="icon marso-icon-tyrestack"></span></div>`);
                }
            }
            if ("undefined" !== typeof stocks.stockCountry &&
                0 < stocks.stockCountry) {
                cell.push(`<div class="grid grid-flow-col">
                    <span class="show-stock-details text-blue font-bold cursor-pointer">${(0, i18n_1.trans)("country", "country", "rim_search")}</span>
                    <span class="font-bold text-right">${stocks.stockCountry}${stocks.stockCountryMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockLocal &&
                0 < stocks.stockLocal) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("local_valve", "of which local", "rim_search")}</span>
                    <span class="font-bold text-right">${stocks.stockLocal}${stocks.stockLocalMax ? "+" : ""}</span>
                </div>`);
            }
            // Nincs szükség expressz készlet megjelenítésére, mert ez DH telephelyen egyezik a helyi készlettel.
            // if (
            //     "undefined" !== typeof stocks.stockExpress &&
            //     0 < stocks.stockExpress
            // ) {
            //     cell.push(`<div class="grid grid-flow-col">
            //         <span>${trans(
            //             "express_valve",
            //             "local",
            //             "rim_search"
            //         )}</span>
            //         <span class="font-bold text-right">${stocks.stockExpress}${
            //         stocks.stockExpressMax ? "+" : ""
            //     }</span>
            //     </div>`);
            // }
            if ("undefined" !== typeof stocks.stockManufacturer &&
                0 < stocks.stockManufacturer) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("manufacturer_valve", "manufacturer", "rim_search")}</span>
                    <span class="font-bold text-right">${stocks.stockManufacturer}${stocks.stocManufacturerMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.stockHun && 0 < stocks.stockHun) {
                cell.push(`<div class="grid grid-flow-col">
                    <span>${(0, i18n_1.trans)("hungarian_valve", "hungarian", "rim_search")}</span>
                    <span class="font-bold text-right">${stocks.stockHun}${stocks.stockHunMax ? "+" : ""}</span>
                </div>`);
            }
            if ("undefined" !== typeof stocks.status) {
                if ("limited" === stocks.status) {
                    cell.push(`<button type="button" class="button-limited">
                        ${(0, i18n_1.trans)("limited_stock", "Limited stock", "rim_search_by_car")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
                else if ("orderable" === stocks.status) {
                    cell.push(`<button type="button" class="button-orderable">
                        ${(0, i18n_1.trans)("orderable", "Orderable", "rim_search_by_car")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
            }
            return cell.length
                ? '<div class="relative">' + cell.join("") + "</div>"
                : "";
        },
    },
    {
        key: "cart",
        visible: true,
        label: "",
    },
];
exports.rimProductListFields = [
    {
        key: "productName",
        value: (item) => {
            return `
<div class="product-name cursor-pointer inline-flex">
    <div class="pr-3 flex justify-center items-center textbox textbox-right"
        data-text="${(0, i18n_1.trans)("product_info", "Product info", "rim_search")}">
        <span class="icon marso-icon-info-circle text-2xl text-blue"></span>
    </div>
    <div>
        <div class="font-bold ">${item.brandName}</div>
        <div><div>${item.treadCode}</div></div>
    </div>
</div>`;
        },
        label: (0, i18n_1.trans)("product_name_rim", "Product name", "rim_search"),
        visible: true,
    },
    {
        key: "size",
        value: (item) => {
            return `<div class="font-bold">${item.size}</div>`;
        },
        label: (0, i18n_1.trans)("size_rim", "Size", "rim_search"),
        visible: true,
    },
    {
        key: "priceList",
        visible: true,
        label: `
            <div>${(0, i18n_1.trans)("list_price_rim", "List price", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("net_list_price_rim", "net", "rim_search")}</div>
            <div class="text-xs ml-1 hide-foreign-customer">${(0, i18n_1.trans)("gross_list_price_rim", "gross", "rim_search")}</div>
        `,
        value: (item, args) => {
            let ret = "";
            let price = item.prices.priceListNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let priceDiscountNet = item.prices.priceDiscountNet;
            if (typeof priceDiscountNet !== "number") {
                priceDiscountNet = parseFloat(priceDiscountNet);
            }
            let currencyCode = item.prices.currencyCode;
            if (args === null || args === void 0 ? void 0 : args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            // show net prices
            ret = `${ret}
                    <div class="font-bold">
                        ${(0, shop_1.formatPrice)(price, currencyCode)}
                    </div>
                `;
            // nincs netto / brutto kapcsolo, de benthagyom, mert kesobb meg lehet
            //if (args.showGrossPriceToggle) {
            // show gross prices
            if (priceVatPercent) {
                ret = `${ret}
                        <div class="font-bold">
                            ${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}
                        </div>
                    `;
            }
            //}
            let discountPercent = (100 - priceDiscountNet / (item.prices.priceListNet / 100)) / 100;
            if (discountPercent > 0) {
                ret = `${ret}<div class="text-red-dark"><i class="hide-foreign-customer marso-icon-promotion_gift"></i> ${(0, i18n_1.n)(discountPercent, "percent")}</div>`;
            }
            return ret;
        },
    },
    {
        key: "priceDiscount",
        visible: true,
        label: `
            <div>${(0, i18n_1.trans)("wholesale_price_rim", "Wholesale price", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("net_wholesale_price_rim", "net", "rim_search")}</div>
            <div class="text-xs ml-1 hide-foreign-customer">${(0, i18n_1.trans)("gross_wholesale_price_rim", "gross", "rim_search")}</div>
        `,
        value: (item, args) => {
            let price = item.prices.priceDiscountNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args === null || args === void 0 ? void 0 : args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let ret = "<div>";
            let discountColorClass = '';
            if (item.prices.needDiscountDisplay) {
                discountColorClass = 'text-red-dark';
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            if (item.prices.priceEprNet) {
                // price net without epr
                ret = `${ret}<div class="${discountColorClass}">${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode)}</div>`;
                // epr net price
                ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle text-green tooltip"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode)}
                    </div>
                `;
            }
            // show net prices
            ret = `${ret}<div class="font-bold ${discountColorClass}">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
            // nincs ilyen kapcsolo a felni kereso oldalon
            // if (args.showGrossPriceToggle) {
            // show gross prices
            if (item.prices.priceEprNet) {
                // price gross without epr
                if (priceVatPercent) {
                    ret = `${ret}
                            <div class="${discountColorClass}">
                                ${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                            </div>
                        `;
                }
                // epr gross price
                ret = `${ret}
                        <div class="text-sm">
                            <i class="relative top-0.5 marso-icon-recycle text-green tooltip"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                        </div>
                    `;
            }
            // gross full price
            if (priceVatPercent) {
                ret = `${ret}
                        <div class="font-bold ${discountColorClass}">
                            ${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}
                        </div>
                    `;
            }
            ret = `${ret}</div>`;
            return ret;
        },
    },
    {
        key: "priceRetail",
        visible: false,
        label: `
            <div>${(0, i18n_1.trans)("retail_price_rim", "Retail price", "rim_search")}</div>
            <div class="text-xs ml-1">${(0, i18n_1.trans)("net_retail_price_rim", "net", "rim_search")}</div>
            <div class="text-xs ml-1 hide-foreign-customer">${(0, i18n_1.trans)("gross_retail_price_rim", "gross", "rim_search")}</div>
        `,
        value: (item, args) => {
            let ret = "";
            let price = item.prices.priceRetailNet;
            if (typeof price !== "number") {
                price = parseFloat(price);
            }
            let currencyCode = item.prices.currencyCode;
            if (args === null || args === void 0 ? void 0 : args.customerCurrencyCode) {
                currencyCode = args.customerCurrencyCode;
            }
            let priceVatPercent = 0;
            if (item.prices.priceVatPercent && typeof item.prices.priceVatPercent !== "number") {
                priceVatPercent = parseFloat(item.prices.priceVatPercent);
            }
            else {
                priceVatPercent = item.prices.priceVatPercent;
            }
            // nincs ilyen kapcsolo a felni kereso oldalon
            // show net price
            if (item.prices.priceEprNet) {
                // price net without epr
                ret = `
                        ${ret}
                        <div>
                            ${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode)}
                        </div>
                    `;
                // epr net price
                ret = `${ret}
                    <div class="text-sm">
                        <i class="relative top-0.5 marso-icon-recycle text-green tooltip"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode)}
                    </div>
                `;
            }
            // net full price
            ret = `${ret}<div class="font-bold">${(0, shop_1.formatPrice)(price, currencyCode)}</div>`;
            //if (args.showGrossPriceToggle) {
            // show gross price
            if (item.prices.priceEprNet) {
                // price gross without epr
                if (priceVatPercent) {
                    ret = `${ret}
                            <div class="hide-foreign-customer">
                                ${(0, shop_1.formatPrice)(price - item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                            </div>
                        `;
                }
                // epr gross price
                ret = `${ret}
                        <div class="text-sm">
                            <i class="relative top-0.5 marso-icon-recycle text-green tooltip"></i>️
                            ${(0, shop_1.formatPrice)(item.prices.priceEprNet, currencyCode, { vatPercent: priceVatPercent })}
                        </div>
                    `;
            }
            if (priceVatPercent) {
                // gross full price
                ret = `
                        ${ret}
                        <div class="font-bold hide-foreign-customer">
                            ${(0, shop_1.formatPrice)(price, currencyCode, { vatPercent: priceVatPercent })}
                        </div>
                    `;
            }
            //}
            return ret;
        },
    },
    {
        key: "stock",
        visible: true,
        label: (0, i18n_1.trans)("stock_rim", "Stock", "rim_search"),
        value: (item) => {
            let cell = [];
            let stocks = item.stocks;
            let detailsCell = [];
            if ("undefined" !== typeof stocks.stockDetails) {
                stocks.stockDetails.forEach((item) => {
                    detailsCell.push(`
                        <div class="grid grid-flow-col">
                            <span class="whitespace-nowrap pr-2" title="${item.name}">${item.name}</span>
                            <span class="font-bold text-right">${item.stock}</span>
                        </div>
                    `);
                });
            }
            if ("undefined" !== typeof stocks.stockDetailsStatus) {
                if (detailsCell.length) {
                    cell.push(`<div class="${stocks.stockDetailsStatus} stock-details-box absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10 cursor-pointer">${detailsCell.join("")}</div>`);
                }
                else {
                    cell.push(`<div class="${stocks.stockDetailsStatus} stock-details-box absolute p-2 bg-blue rounded min-w-full -right-4 text-white shadow z-10 text-center text-xl cursor-pointer"><span class="icon marso-icon-tyrestack"></span></div>`);
                }
            }
            if ("undefined" !== typeof stocks.stockCountry &&
                0 < stocks.stockCountry) {
                cell.push(`<div class="grid grid-flow-col"><span class="show-stock-details text-blue font-bold">${(0, i18n_1.trans)("country_rim", "country", "rim_search")}</span><span class="font-bold text-right">${stocks.stockCountry}</span></div>`);
            }
            if ("undefined" !== typeof stocks.stockLocal &&
                0 < stocks.stockLocal) {
                cell.push(`<div class="grid grid-flow-col"><span>${(0, i18n_1.trans)("local_rim", "of which local", "rim_search")}</span><span class="font-bold text-right">${stocks.stockLocal}</span></div>`);
            }
            // Nincs szükség expressz készlet megjelenítésére, mert ez DH telephelyen egyezik a helyi készlettel.
            // if (
            //     "undefined" !== typeof stocks.stockExpress &&
            //     0 < stocks.stockExpress
            // ) {
            //     cell.push(
            //         `<div class="grid grid-flow-col"><span>${trans(
            //             "express_rim",
            //             "local",
            //             "rim_search"
            //         )}</span><span class="font-bold text-right">${
            //             stocks.stockExpress
            //         }</span></div>`
            //     );
            // }
            if ("undefined" !== typeof stocks.stockManufacturer &&
                0 < stocks.stockManufacturer) {
                cell.push(`<div class="grid grid-flow-col"><span>${(0, i18n_1.trans)("manufacturer_rim", "manufacturer", "rim_search")}</span><span class="font-bold text-right">${stocks.stockManufacturer}</span></div>`);
            }
            if ("undefined" !== typeof stocks.stockHun && 0 < stocks.stockHun) {
                cell.push(`<div class="grid grid-flow-col"><span>${(0, i18n_1.trans)("hungarian_rim", "hungarian", "rim_search")}</span><span class="font-bold text-right">${stocks.stockHun}</span></div>`);
            }
            if ("undefined" !== typeof stocks.status) {
                if ("limited" === stocks.status) {
                    cell.push(`<button type="button" class="button-limited">
                        ${(0, i18n_1.trans)("limited_stock", "Limited stock", "rim_search_by_car")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
                else if ("orderable" === stocks.status) {
                    cell.push(`<button type="button" class="button-orderable">
                        ${(0, i18n_1.trans)("orderable", "Orderable", "rim_search_by_car")}
                        <span class="tooltip"><span class="icon marso-icon-info-circle"></span></span>
                    </button>`);
                }
            }
            return cell.length
                ? '<div class="relative">' + cell.join("") + "</div>"
                : "";
        },
    },
    {
        key: "cart",
        visible: true,
        label: "",
    },
];
