"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShippingModeEnum = void 0;
var ShippingModeEnum;
(function (ShippingModeEnum) {
    ShippingModeEnum["COURIER"] = "courier";
    ShippingModeEnum["PERSONAL"] = "personal";
    ShippingModeEnum["PERSONAL_AT_PICKUP_POINT"] = "personalAtPickupPoint";
    ShippingModeEnum["GLS_FLEX_DELIVERY"] = "glsFlexDelivery";
    ShippingModeEnum["EXPRESS"] = "express";
    ShippingModeEnum["GLS"] = "gls";
    ShippingModeEnum["FULL_TRUCK"] = "fullTruck";
    ShippingModeEnum["DACHSER"] = "dachser";
    ShippingModeEnum["TRUCK_TRANSPORT"] = "truckTransport";
})(ShippingModeEnum = exports.ShippingModeEnum || (exports.ShippingModeEnum = {}));
